import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {chat} from "./functions/chat";


  function Answer(props) {
    return (
      <div className="gpt-answer">
        <p key={props.ai_answer}className="gpt-answer-p">{props.ai_answer}</p>
      </div>
    );

  }

  
  class App extends React.Component {
      constructor(props){
      super(props);
      this.state = {ai_answer: "", ai_question: ""};
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
      this.setState({ai_question: event.target.value});  
      event.preventDefault();

    }

    handleSubmit(event) {
      event.preventDefault(); // need this top prevent a page reload

    }

    render() {

      return ( 
        <div className="gpt-background">
          <div className="gpt-chat">
            <div className="gpt-heading">
              <img src="/rachgpt_owl.png" alt="RachGPT logo" height="85px"/>
              <h3 className="gpt-heading" >RachGPT</h3>
              <br/><br/>
            </div>
            <form className="gpt-form" onSubmit={this.handleSubmit}>
              <textarea className="gpt-input-question" placeholder="Ask RachGPT whatever you want" id="question" value={this.state.ai_question} onChange={this.handleChange} type="text"></textarea>
              <button className="gpt-btn-ask" onClick={()=>[this.setState({ai_answer: ""}),chat(this.state.ai_question).then((data)=>(this.setState({ai_answer: data})))]}>Send</button>
            </form> 
          </div>
          <Answer ai_answer={this.state.ai_answer}/>
        </div>
      );
    }
  }
  


  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    <App />
  )

  