// have to use async and await to get this code to run sequentially
async function chat(question){
        const url = process.env.REACT_APP_API_HOST + '/chat';
        let res = await fetch(
            url,
            {
                method: 'POST',
                headers: { 'Content-Type':'application/json'},
                body : JSON.stringify({'content': question})
            });


        let data = await res.json();
        if(Object.hasOwn(data, "error")){

            return "We're sorry. There was a problem.";
        }
        else{
            return data.ai_answer;
        }
    
    }


export {chat};